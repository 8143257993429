<template>
  <div class="main-page">
    <div class="search-filter-form">
      <div class="filter-area d-flex">
        <div class="select mt-0">
          <b-form-select :value="selectedProject" class="inner_select" @change="selectOption">
            <b-form-select-option v-for="option in projectBodies" :key="option.Seq" :value="option.Seq" :label="option.ProjectNM"></b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="card mt-1 mb-0">
      <b-table :fields="tableHeads" :items="tableRows" responsive :class="{ nodata: displayClass === 'nodata', load_ing: displayClass === 'loading' }" class="adjust-table text-center">
        <template v-slot:head(0)="data">
          <span class="word-keep-all">
            기기<br class="d-lg-none" />
            <span class="d-none d-md-inline">/</span>상태
          </span>
        </template>

        <template v-slot:cell(0)="{ value }">
          <div class="d-flex flex-column flex-md-row gap-.5 justify-content-center">
            <img class="cell-img" v-if="value['MGubun'] === 'PC'" src="@/assets/images/custom/pc.png" />
            <img class="cell-img" v-else-if="value['MGubun'] === 'M'" src="@/assets/images/custom/mobile.png" />
            <img class="cell-img" v-else src="@/assets/images/custom/mobile_more.png" />

            <span class="d-none d-md-inline"> / </span>
            <img class="cell-img" v-if="value['상태'] === '정상'" src="@/assets/images/custom/media_play.png" />
            <img class="cell-img" v-else-if="value['상태'] === 'PAUSE'" src="@/assets/images/custom/media_stop_red.png" />
            <img class="cell-img" v-else src="@/assets/images/custom/warning.png" />
          </div>
        </template>
        <template v-slot:cell(1)="{ value }"><div class="min-w-40">{{ value['Keyword'] }}</div></template>
        <template v-slot:cell(2)="{ value }"> 목표: {{ value['L목표순위'] }}<br />현재: {{ value['현재순위'] }}</template>
        <template v-slot:cell(3)="{ value }"> 최대: {{ formatCurrency(value['최상제한금액']) }}<br />현재: {{ formatCurrency(value['현재입찰액']) }}</template>
        <template v-slot:cell(4)="{ value }">
          <b-button size="sm word-keep-all" class="p-.5" @click="showLog(value['nccKeywordId'])">입찰<br />로그</b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatCurrency } from '@/utils/StringUtils'

export default {
  data() {
    return {
      selectedProject: undefined,
      list: undefined
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('filterParam', ['params']),
    ...mapGetters('bid', ['projectBodies']),
    displayClass() {
      if (this.loading && !this.list) {
        return 'loading'
      } else if (!this.loading && this.tableRows && this.tableRows.length === 0) {
        return 'nodata'
      } else {
        return ''
      }
    },
    tableHeads() {
      return [
        { key: '0', label: '기기상태', class: 'cell-padding text-center' },
        { key: '1', label: '키워드', class: 'cell-padding text-center' },
        { key: '2', label: '순위', class: 'cell-padding text-center' },
        { key: '3', label: '입찰가', class: 'cell-padding text-center' },
        { key: '4', label: '로그', class: 'cell-padding text-center' }
      ]
    },
    tableRows() {
      return (this.list?.Body || []).map(item => {
        const obj = {
          0: {
            MGubun: item['MGubun'],
            상태: item['상태'],
            class: 'cell-padding text-center'
          },
          1: {
            Keyword: item['Keyword'],
            class: 'cell-padding text-center'
          },
          2: {
            현재순위: item['현재순위'],
            L목표순위: item['L목표순위'],
            class: 'cell-padding text-center'
          },
          3: {
            최상제한금액: item['최상제한금액'],
            현재입찰액: item['현재입찰액'],
            class: 'cell-padding text-center'
          },
          4: {
            nccKeywordId: item['nccKeywordId'],
            class: 'cell-padding text-center'
          }
        }
        return obj
      })
    }
  },
  methods: {
    ...mapActions('bid', ['fetchProjects', 'fetchMainList']),
    ...mapMutations('bid', ['SELECT_LOG_KEYWORD']),
    ...mapMutations('common', ['SET_LOADING']),

    formatCurrency,

    selectOption(value) {
      if (this.selectedProject === value) return
      this.selectedProject = value

      this.search()
    },

    search() {
      this.list = undefined
      this.SET_LOADING(true)
      return this.fetchMainList(this.selectedProject)
        .then(res => (this.list = res.data))
        .then(() => this.SET_LOADING(false))
    },
    showLog(nccKeywordidId) {
      this.SELECT_LOG_KEYWORD({ project: this.selectedProject, nccKeywordidId })
      this.$router.push('/bidLogs')
    }
  },
  created() {
    this.SET_LOADING(true)
    this.fetchProjects()
      .then(() => this.selectOption(this.projectBodies[0]?.Seq))
      .then(() => this.SET_LOADING(false))
  }
}
</script>
