var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-page"},[_c('div',{staticClass:"search-filter-form"},[_c('div',{staticClass:"filter-area d-flex"},[_c('div',{staticClass:"select mt-0"},[_c('b-form-select',{staticClass:"inner_select",attrs:{"value":_vm.selectedProject},on:{"change":_vm.selectOption}},_vm._l((_vm.projectBodies),function(option){return _c('b-form-select-option',{key:option.Seq,attrs:{"value":option.Seq,"label":option.ProjectNM}})}),1)],1)])]),_c('div',{staticClass:"card mt-1 mb-0"},[_c('b-table',{staticClass:"adjust-table text-center",class:{ nodata: _vm.displayClass === 'nodata', load_ing: _vm.displayClass === 'loading' },attrs:{"fields":_vm.tableHeads,"items":_vm.tableRows,"responsive":""},scopedSlots:_vm._u([{key:"head(0)",fn:function(data){return [_c('span',{staticClass:"word-keep-all"},[_vm._v(" 기기"),_c('br',{staticClass:"d-lg-none"}),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("/")]),_vm._v("상태 ")])]}},{key:"cell(0)",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex flex-column flex-md-row gap-.5 justify-content-center"},[(value['MGubun'] === 'PC')?_c('img',{staticClass:"cell-img",attrs:{"src":require("@/assets/images/custom/pc.png")}}):(value['MGubun'] === 'M')?_c('img',{staticClass:"cell-img",attrs:{"src":require("@/assets/images/custom/mobile.png")}}):_c('img',{staticClass:"cell-img",attrs:{"src":require("@/assets/images/custom/mobile_more.png")}}),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(" / ")]),(value['상태'] === '정상')?_c('img',{staticClass:"cell-img",attrs:{"src":require("@/assets/images/custom/media_play.png")}}):(value['상태'] === 'PAUSE')?_c('img',{staticClass:"cell-img",attrs:{"src":require("@/assets/images/custom/media_stop_red.png")}}):_c('img',{staticClass:"cell-img",attrs:{"src":require("@/assets/images/custom/warning.png")}})])]}},{key:"cell(1)",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"min-w-40"},[_vm._v(_vm._s(value['Keyword']))])]}},{key:"cell(2)",fn:function(ref){
var value = ref.value;
return [_vm._v(" 목표: "+_vm._s(value['L목표순위'])),_c('br'),_vm._v("현재: "+_vm._s(value['현재순위']))]}},{key:"cell(3)",fn:function(ref){
var value = ref.value;
return [_vm._v(" 최대: "+_vm._s(_vm.formatCurrency(value['최상제한금액']))),_c('br'),_vm._v("현재: "+_vm._s(_vm.formatCurrency(value['현재입찰액'])))]}},{key:"cell(4)",fn:function(ref){
var value = ref.value;
return [_c('b-button',{staticClass:"p-.5",attrs:{"size":"sm word-keep-all"},on:{"click":function($event){return _vm.showLog(value['nccKeywordId'])}}},[_vm._v("입찰"),_c('br'),_vm._v("로그")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }